
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import AdminBillingPlans from '@/components/pages/admin/billing/AdminBillingPlans.vue'
import AdminBillingAddons from '@/components/pages/admin/billing/AdminBillingAddons.vue'
import AdminBillingTotal from '@/components/pages/admin/billing/AdminBillingTotal.vue'
import AdminBillingAutoRecharge from '@/components/pages/admin/billing/AdminBillingAutoRecharge.vue'
import AdminBillingDetails from '@/components/pages/admin/billing/AdminBillingDetails.vue'
import AdminBillingPaymentMethods from '@/components/pages/admin/billing/AdminBillingPaymentMethods.vue'
import GettingStartedFaq from '@/components/pages/gettingStarted/GettingStartedFaq.vue'
import { paymentMethods } from '@/definitions/account/billing/data'
import { useModes } from '@/compositions/modes'
import { useModals } from '@/compositions/modals'
import type { PlanType, AddonType } from '@/definitions/admin/billing/types'
import { useRouter } from 'vue-router'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    AdminBillingDetails,
    AdminBillingPaymentMethods,
    AdminBillingAutoRecharge,
    AdminBillingTotal,
    AdminBillingPlans,
    AdminBillingAddons,
    WhiteBlock,
    PageContent,
    TmTooltip,
    TmButton,
    GettingStartedFaq,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode, isErrorMode, getMode } = useModes()
    const { openModal } = useModals()
    const router = useRouter()
    const { isSmMax, isXlRange, is2XlRange } = useBreakpoints()

    const payment = computed(() => isEmptyMode.value ? [] : paymentMethods)
    const autoRechargeStatus = ref(true)

    const addons = ref<AddonType[]>([
      {
        icon: 'tmi-phone-dialpad',
        title: 'Virtual numbers',
        text: '4 numbers',
        to: { name: 'base.services.senderSettings.numbers' },
        cost: '40.2',
      },
      {
        icon: 'domain',
        title: 'Sender ID',
        text: '1 sender ID',
        to: { name: 'base.services.senderSettings.senderIDs' },
        cost: '10',
      },
      {
        icon: 'tmi-phone-geo',
        title: '10DLC',
        text: '4 campaigns',
        to: { name: 'base.services.senderSettings.10DLC' },
        cost: '46.8',
      },
    ])

    const cpaasTooltip = computed(() => {
      const currentMode = getMode()
      switch (currentMode.value) {
        case 'emptyHalf': return 'You’ve almost reached the message limit of your monthly plan. Upgrade to a higher plan now for uninterrupted messaging via CPaaS.'
        case 'error': return 'You’ve reached the message limit of your monthly plan. Upgrade to a higher monthly plan to continue messaging via CPaaS.'
        default: return 'This progress bar shows how many messages you’ve sent via CPaaS and your message limit on the monthly plan.'
      }
    })

    const usersTooltip = computed(() => {
      const currentMode = getMode()
      switch (currentMode.value) {
        case 'emptyHalf': return 'You’ve almost reached the user limit of your monthly plan. Upgrade to a higher plan to invite more users to your account.'
        case 'error': return 'You’ve reached the user limit of your monthly plan. Upgrade to a higher plan to invite more users to your account.'
        default: return 'This progress bar shows how many active or invited users there are in your account and your user limit on the monthly plan.'
      }
    })

    const plans = computed<PlanType[]>(() => [
      {
        icon: 'credit_card',
        title: 'Premium plan',
        tooltip: '<span class="font-weight-semi-bold">Activated on 22 Feb 2024</span><br>Monthly plan credit covers all standard services (subscriptions, MMS, calls, etc) with reduced outbound SMS rates.',
        text: 'Next renewal: 23 Feb 2024',
        btnText: (isEmptyHalfMode.value || isErrorMode.value) ? 'Upgrade plan' : 'Manage plan',
        btnColor: (isEmptyHalfMode.value || isErrorMode.value) ? 'warning' : '',
        cost: '499',
        progress: [
          {
            name: isSmMax.value || isXlRange.value || is2XlRange.value ? 'BYOC' : 'Bring your own CPaaS',
            textProgress: isErrorMode.value ? '25,000 / 25,000' : (isEmptyHalfMode.value ? '22,000 / 25,000' : '14,000 / 25,000'),
            tooltip: cpaasTooltip.value,
            value: isErrorMode.value ? 1 : (isEmptyHalfMode.value ? 0.9 : 0.6),
            progressColor: (isEmptyHalfMode.value || isErrorMode.value) ? 'warning--text' : '',
            separate: true,
          },
          {
            name: 'Users',
            textProgress: isErrorMode.value ? '50 / 50' : (isEmptyHalfMode.value ? '45 / 50' : '24 / 50'),
            tooltip: usersTooltip.value,
            value: isErrorMode.value ? 1 : (isEmptyHalfMode.value ? 0.9 : 0.45),
            progressColor: (isEmptyHalfMode.value || isErrorMode.value) ? 'warning--text' : '',
            separate: true,
          },
        ],
        clickEvent: () => {
          router.push({ name: 'base.admin.plans' })
        },
      },
    ])

    const billingTotal = computed<PlanType>(() => ({
      icon: 'account_balance_wallet',
      title: 'Total estimated cost',
      tooltip: 'The estimated total cost includes the chosen monthly plan and any extra credit top-ups.',
      cost: '2,115',
      text: 'Period: Feb 1 - Feb 29',
    }))

    return {
      addons,
      plans,
      payment,
      autoRechargeStatus,
      billingTotal,
      openModal,
    }
  },
})
